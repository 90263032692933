import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const [formSubmitted, setFormSubmitted] = useState(false)

  const sendMsg = data => {
    fetch(`https://web-bot.kit.kit-med.com/send-message`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data })
    })
      .then()
      .catch(e => console.log(e))
  }

  const onSubmit = (data, e) => {
    e.target.reset()
    sendMsg(data)
    setFormSubmitted(true)
  }

  const currentTime = new Date().getHours()
  const isWorkTime = !!(currentTime < 18 && currentTime > 9)

  return (
    <>
      {!formSubmitted ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control theme-light"
                  placeholder="Имя, обязательно"
                  {...register('name', { required: true })}
                />
                {errors.name && errors.name.type === 'required' && (
                  <span className="invalid-feedback">Введите Ваше имя</span>
                )}
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-group mb-3">
                <input
                  type="tel"
                  className="form-control "
                  placeholder="Телефон, обязательно"
                  {...register(
                    'phone',
                    {
                      required: 'Укажите телефон, для связи с Вами',
                      minLength: {
                        value: 11,
                        message: 'Короткий номер, минимальная длинна 11 символов'
                      },
                      pattern: {
                        value: /^\d+$/,
                        message: 'Введите номер телефона, используйте просто цифры'
                      }
                    },
                    { required: true }
                  )}
                />
                {errors.phone && <span className="invalid-feedback">{errors.phone.message}</span>}
              </div>
            </div>

            <div className="col-12">
              <div className="form-group mb-3">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Какую вы используете МИС?"
                  {...register('mis', { required: false })}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group mb-3">
                <input
                  type="tel"
                  className="form-control "
                  placeholder="Какое количество визитов в месяц?"
                  {...register('patientPerMonth', {
                    required: false,
                    pattern: {
                      value: /^\d+$/,
                      message: 'Введите количество визитов клиники в месяц'
                    }
                  })}
                />
                {errors.patientPerMonth && <span className="invalid-feedback">{errors.patientPerMonth.message}</span>}
              </div>
            </div>

            <div className="col-12">
              <div className="btn-bar">
                <button className="px-btn px-btn-white">Отправить</button>
              </div>
            </div>
          </div>
        </form>
      ) : (
        <div className="row">
          <div className="col-12">
            <h1>
              {isWorkTime
                ? 'Благодарим за доверие, свяжемся с вами в ближайшее время 🚀'
                : 'Благодарим за доверие, свяжемся с вами в рабочие часы, с 9 до 18 (МСК) 🚀'}
            </h1>
          </div>
        </div>
      )}
    </>
  )
}

export default Contact
